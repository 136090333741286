<template>
  <div>
    <BaseTable
      v-model="selectedRecords"
      :headers="dupeSetsToReviewTableHeaders"
      :records="dupeSetsToReview"
      :per-page="dupeSetsToReviewPerPage"
      :total-items="dupeSetsToReviewTotalItems"
      :is-loading="isLoading"
      :title="title"
      disable-dragging
      disable-resizing
      tooltip="This list contains duplicate sets requiring review. Click anywhere within a row to review."
      show-select
      @open="openDuplicateSetsDialog"
      @fetchData="fetchData"
    >
      <template #search="{ search, updateSearch }">
        <AdvancedDashboardFilter
          :search="search"
          :update-search="updateSearch"
          max-width="550px"
        />
      </template>
      <template #action>
        <v-slide-x-reverse-transition>
          <v-btn
            v-show="selectedRecords.length > 0"
            color="primary"
            height="40"
            outlined
            class="mr-1"
            @click="showRemoveDialog = true"
          >
            REMOVE SELECTED
          </v-btn>
        </v-slide-x-reverse-transition>
        <v-btn
          v-show="dupeSetsToReview.length > 0"
          color="primary"
          height="40"
          @click="openDuplicateSetsDialog()"
        >
          {{ selectedRecords.length > 0 ? 'REVIEW SELECTED' : 'REVIEW ALL' }}
        </v-btn>
      </template>
    </BaseTable>

    <ApDialog
      v-model="showRemoveDialog"
      title="Not Duplicates"
      title-color-class="ap-green--text"
      max-width="440"
      is-content-centered
      :content="[
        'You have indicated that the records in the set should not be considered duplicates.',
        'By confirming, this set will no longer be presented to you.',
      ]"
      :actions="actions"
      @cancel="showRemoveDialog = false"
      @confirm="removeDuplicates"
    />

    <template v-if="isNewDupegridEnabled">
      <DupeGridDialog
        v-if="showDupesGridDialog"
        title="Duplicate Set"
        :records="dialogDupeSets"
        @close="showDupesGridDialog = false"
      />
    </template>

    <template v-else>
      <DupesGridDuplicateSetsDialog
        v-model="showDupesGridDialog"
        :dupe-sets="dialogDupeSets"
      />
    </template>
  </div>
</template>

<script>
import { archiveDupeSet } from '@/api/dupe-sets/dupe-sets'
import { mapState, mapGetters, mapActions } from 'vuex'
import BaseTable from './BaseTable'
import DupesGridDuplicateSetsDialog from '@/components/dupesgrid/DupesGridDuplicateSetsDialog'
import AdvancedDashboardFilter from '@/components/shared/AdvancedDashboardFilter'
import ApDialog from '@/components/common/ApDialog'
import DupeGridDialog from '../dupegrid/DupeGridDialog'

export default {
  components: {
    BaseTable,
    DupesGridDuplicateSetsDialog,
    AdvancedDashboardFilter,
    ApDialog,
    DupeGridDialog,
  },
  props: {
    title: {
      type: String,
      default: 'Duplicate sets to be reviewed',
    },
  },
  data() {
    return {
      isLoading: false,
      selectedRecords: [],
      showDupesGridDialog: false,
      dialogDupeSets: [],
      isLoadingAction: false,
      showRemoveDialog: false,
      paginationItemsPerPage: 10,
      recordOptions: {},
      isNewDupegridEnabled:
        process.env.VUE_APP_NEW_DUPEGRID_ENABLED === 'true' ||
        localStorage.getItem('is_new_dupegrid_enabled') === 'true',
    }
  },
  computed: {
    ...mapState('dashboard', [
      'dupeSetsToReview',
      'dupeSetsToReviewPerPage',
      'dupeSetsToReviewTotalItems',
    ]),
    ...mapGetters('dashboard', ['dupeSetsToReviewTableHeaders']),
    actions() {
      return [
        {
          event: 'cancel',
          label: 'Let me doulbe check',
          disabled: this.isLoadingAction,
          outlined: true,
          color: 'primary',
        },
        {
          event: 'confirm',
          label: 'Confirm',
          disabled: this.isLoadingAction,
          color: 'primary',
          leftIcon: '$mdi-check',
        },
      ]
    },
  },
  watch: {
    showDupesGridDialog(val) {
      if (!val) this.fetchData()
    },
  },
  methods: {
    ...mapActions('dashboard', ['getDupeSetsToReview']),
    openDuplicateSetsDialog(item) {
      if (item) {
        this.dialogDupeSets = [item]
      } else if (this.selectedRecords.length > 0) {
        this.dialogDupeSets = this.selectedRecords
      } else {
        this.dialogDupeSets = this.dupeSetsToReview
      }

      this.showDupesGridDialog = true
    },
    async fetchData(options) {
      this.isLoading = true

      if (options) {
        this.recordOptions = options
      }

      await this.getDupeSetsToReview({
        perPage: this.paginationItemsPerPage,
        ...this.recordOptions,
      })

      this.isLoading = false
    },
    async removeDuplicates() {
      this.isLoadingAction = true

      const promises = []

      this.selectedRecords.forEach((record) => {
        promises.push(archiveDupeSet(record.id))
      })

      await Promise.all(promises)

      this.isLoadingAction = false

      this.showRemoveDialog = false

      // refresh table
      await this.fetchData()
    },
  },
}
</script>
