<template>
  <TableDuplicateSets />
</template>

<script>
import TableDuplicateSets from '@/components/tables/TableDuplicateSets'

export default {
  components: {
    TableDuplicateSets,
  },
}
</script>
