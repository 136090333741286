<template>
  <TableMergedDuplicateSets
    :is-loading="isLoading"
    @load="fetchData"
    @export="exportData"
  />
</template>

<script>
import TableMergedDuplicateSets from '@/components/tables/TableMergedDuplicateSets'
import { mapActions } from 'vuex'
import { exportDupeSets } from '@/api/dupe-sets/dupe-sets'
import { dupeSetsStates } from '@/api/dupe-sets/constants'

export default {
  components: {
    TableMergedDuplicateSets,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    ...mapActions('dashboard', ['getMergedDupeSets']),
    async fetchData(options) {
      this.isLoading = true

      await this.getMergedDupeSets({
        perPage: 10,
        ...options,
      })

      this.isLoading = false
    },
    async exportData(options) {
      this.isLoading = true
      try {
        await exportDupeSets({
          exportFormat: 'csv',
          states: [dupeSetsStates.FINISHED],
          ...options,
        })

        this.$router.push({
          name: 'root/system-performance/jobs',
        })
      } catch (error) {
        console.error('AP:exportDupeSetsError', error)
      }
      this.isLoading = false
    },
  },
}
</script>
